import React from 'react'

import Layout from '../components/layout'

export default () => (
  <Layout>
    <h1>About me</h1>
    <p>I code mostly scala, typescript and haskell. On this blog I put all things I learned or find interessting.</p>
  </Layout>
)
